@import './../../theme/base';
@import '~@hagerty/design-system/src/components/site-footer/_index.scss';

.cf-footer {
  padding: 32px 16px;
  margin-top: 0;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      display: block;
      padding: 0;

      .cf-footer__links a {
        margin: 0;
      }
    }
  }

  a {
    display: inline-block;
    color: #51565c;
    margin: 28px 32px 0 0;
    font-size: 14px;
    line-height: 14px;
  }

  &__copyright {
    font-size: 14px;
    line-height: 14px;
    color: #51565c;
  }

  @media (min-width: $mq-medium) {
    justify-content: space-between;
    padding: 30px 64px;

    a {
      margin: 0 0 0 32px;
    }
  }

  @media (max-width: 1570px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (width: 768px) {
    padding-left: 64px;
    padding-right: 64px;
  }

  @media (max-width: 767px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (max-width: 480px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
