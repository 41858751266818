@import '@hagerty/design-system/src/utilities/functions/deep-map-check';
@import '@hagerty/design-system/src/utilities/functions/deep-map-get';
@import '@hagerty/design-system/src/utilities/functions/get';
@import '@hagerty/design-system/src/utilities/functions/pem';
@import '@hagerty/design-system/src/utilities/functions/rem';
@import '@hagerty/design-system/src/utilities/functions/str-explode';
@import '@hagerty/design-system/src/_variables';
@import '@hagerty/design-system/src/dependencies/_font-properties';
@import '@hagerty/design-system/src/dependencies/_sizing';
@import '@hagerty/design-system/src/dependencies/_links';
@import '@hagerty/design-system/src/dependencies/_forms';

.page-wrap {
  transition: opacity 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 400ms;

  &.page-wrap--fade-out {
    opacity: 0;
    transition: opacity 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}

.button_arrow {
  position: relative;
  top: 3px;
  margin-left: 8px;
}

.button_link {
  font-family: GT America;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: white;
  position: relative;

  @media screen and (min-width: 480px) {
    margin-left: 64px;
  }

  &:hover {
    color: white;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    background-color: #007bb3;
    width: 100%;
    height: 3px;
  }
}

.years-top {
  margin: 0 auto;
  max-width: 1440px;
}

.years-next-notice {
  margin: 40px auto 0 auto;
  max-width: 1440px;
  font-family: GT America;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #484c51;
  padding: 0;
  margin-bottom: 142px;

  &--extra-space {
    margin-top: 64px;
  }
}

.container_center--subs {
  min-height: 500px;
}

.lock-past-auctions {
  position: relative;
  padding: 64px 68px 108px 68px;
  margin-top: 166px;
  margin-bottom: 150px;

  @media screen and (max-width: 480px) {
    padding: 32px 24px 48px 24px;

    .button.button_primary {
      margin: 0 auto;
      text-align: center;
      max-width: 225px;
      display: block;
    }

    h4 {
      font-size: 28px;
      line-height: 36px;
    }
  }

  h4 {
    padding-top: 0;
    padding-bottom: 24px;
    font-family: GT America;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 42px;
    color: #ffffff;
  }

  p {
    padding-bottom: 48px;
    color: white;
    max-width: 716px;
    font-size: 18px;
    line-height: 28px;
  }

  .button.button_primary {
    background-color: white;
    color: #090a0b;
  }
}

.lock-past-auctions-bg {
  position: absolute;
  top: 0;
  right: 50px;

  @media screen and (max-width: 480px) {
    display: none;
  }
}

.lock-past-auctions-overlay {
  background: rgba(9, 10, 11, 0.85);
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lock-past-auctions-inner {
  position: relative;
  z-index: 10;
}

.card-grid-related-articles {
  @media (min-width: 780px) {
    &.num-cards-1,
    &.num-cards-2 {
      justify-content: flex-start;

      .card {
        max-width: 33%;
      }
    }
  }

  h4 {
    padding-top: 0;
  }

  .media_image {
    width: 100%;
  }

  .media_image-bg {
    width: 100%;
    height: 255px;
    background-size: cover;
    background-position: center;

    @media (max-width: 1454px) {
      height: 17.538vw;
    }

    @media (max-width: 1279px) {
      height: 20vw;
    }

    @media (max-width: 784px) {
      height: 28vw;
    }

    @media (max-width: 479px) {
      height: 58vw;
    }
  }

  .card {
    @media (min-width: 480px) and (max-width: 784px) {
      flex: 1 0 45%;

      &:nth-of-type(3) {
        margin: 0;
      }
    }

    @media (max-width: 784px) {
      &:nth-of-type(3) {
        .media_image-bg {
          height: 50vw;
        }
      }
    }

    @media (max-width: 479px) {
      &:nth-of-type(3) {
        .media_image-bg {
          height: 58vw;
        }
      }
    }
  }
}

.card__no-image {
  width: 100%;
  height: 219.02px;
  margin: 8px 0 16px;
  background-color: #eee;
}

.section-overview {
  .text-heading_3 {
    margin-top: 72px;
  }

  .section__head {
    padding-top: 80px;
    padding-bottom: 0;
  }
}

.button-group__item--create-account {
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.button_link--create-account {
  @media screen and (max-width: 480px) {
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.cf-summary__dropdown {
  background-color: transparent;
  -webkit-appearance: none;
}

.section-overview .text-heading_3.text-heading_3--reduce-space {
  margin-top: 62px;
}

.section-valuation-articles {
  margin-bottom: 134px;

  @media (max-width: 480px) {
    margin-bottom: 60px;
  }

  .card-stack__image {
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    overflow: hidden;
  }

  @media screen and (max-width: 780px) {
    .card-stack__image {
      margin-bottom: 32px;
    }
  }

  @media screen and (max-width: 480px) {
    .card-stack__content-wrapper {
      flex-direction: column;
    }
  }
}

.container.container_center {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
}

.card-grid-related-articles .card {
  padding-top: 0;
}

.section__title {
  font-stretch: normal !important;
}

.section-community {
  margin-top: 150px;
  margin-bottom: 150px;

  @media screen and (max-width: 480px) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .section__head {
    max-width: 100%;
  }
}

$button-size: 32px;

.alert {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 64px;
  background-color: $color-status-unknown;
  color: $color-dark-1;
  padding: $space-m;
  margin-bottom: $space-m;
  border-radius: $border-radius-large;

  @media (min-width: $mq-large) {
    padding: $space-m 14px;
  }

  // Contains header/message content
  &__container {
    margin-right: auto;
    @media (min-width: $mq-large) {
      padding: $space-s 0;
    }
  }

  // Sets header and message content to block on mobile
  &__header,
  &__message {
    @media (max-width: $mq-large - 1) {
      display: block;
    }
  }

  // Alert resets
  a {
    color: $color-dark-1;
    text-decoration: underline;
  }

  p,
  li {
    font-size: inherit;
  }

  // Alert Header
  &__header {
    @include font-properties($text-heading-level-5);
    padding-right: $space-s;
  }

  // Alert actions (button)
  &__actions {
    .action_button {
      background-color: $color-dark-1;
      color: $color-white;
    }
  }

  // Icons within an alert
  &_icon,
  &_close {
    appearance: none;
    background-color: transparent;
    border: 0;
  }

  &_icon {
    margin-right: $space-s;
    flex-shrink: 0;
    align-self: flex-start;
    position: relative;
    top: 8px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  /**
   * @summary Initializes grouped (collapsible) alerts
   * @selector .alert_dismissable
   * @restrict .alert
   * @variant
   */
  &_dismissible {
    padding-right: $button-size + $space-m;

    @media (min-width: $mq-large) {
      padding-right: $button-size + $space-l;
    }

    .alert_close {
      position: absolute;
      color: $color-dark-1;
      right: $space-s;
      top: $space-s;
      cursor: pointer;
      @include square($button-size);
      border-radius: $border-radius-circle;
      outline: 0;

      &:hover,
      &:focus {
        background-color: $color-dark-05;
        .icon {
          fill: $color-dark-1;
        }
      }

      @media (min-width: $mq-large) {
        right: $space-ml;
        top: 20px;
      }

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  /**
   * @summary Initializes global alerts
   * @selector .alert_global
   * @restrict .alert
   * @variant
   */
  &_global {
    border-radius: 0;
    .alert__container {
      padding: 0;
    }
    .alert_close {
      @media (min-width: $mq-large) {
        top: $space-m;
      }
    }
  }

  /**
   * @summary Initializes grouped (collapsible) alerts
   * @selector .alert__group
   */
  &__group {
    width: 100%;
    margin-bottom: $space-m;

    .alert_collapsible {
      width: 100%;
      cursor: pointer;
      margin-bottom: 0;
      text-align: left;
    }

    .alert__panel {
      border: 1px solid $color-light-2;
      border-top: 0;
      padding: 0 $space-m;
      border-bottom-left-radius: $border-radius-large;
      border-bottom-right-radius: $border-radius-large;
      opacity: 0;
      visibility: hidden;
      height: 0;
      transition: all $duration-default;

      @media (min-width: $mq-large) {
        padding: 0 $space-ml;
      }

      .alert__panel-item {
        position: relative;
        color: $color-dark-1;
        padding: $space-m 0;
        border-bottom: 1px solid $color-light-2;

        &:last-child {
          border-bottom: 0;
        }

        .alert__container {
          padding: 0;
        }
      }

      .alert__header {
        font-weight: normal;
      }

      &_date {
        font-size: rem(12);
        line-height: 1.167;
        color: $color-dark-2;

        @media (max-width: $mq-large) {
          padding-top: $space-s;
        }
      }
    }

    .alert__actions {
      padding-left: $space-ml;
      padding-right: $space-s;
      .icon_toggle {
        transition: all $duration-default;
        margin-top: -1px;
      }
    }

    .alert_icon {
      align-self: flex-start;

      @media (min-width: $mq-medium) {
        align-self: center;
      }
    }

    &.expanded {
      .alert__panel {
        visibility: visible;
        opacity: 1;
        height: 100%;
      }
      .alert_collapsible {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .alert__actions .icon_toggle {
        transform: rotate(180deg);
      }
    }
  }

  /**
   * @summary Color variants for alerts
   * @restrict .alert
   * @variant
   */
  &_info {
    margin: 0 0 16px 0;
    background-color: $color-status-normal-light;

    .alert_icon {
      fill: $color-status-normal;
    }

    &_dark {
      background-color: $color-dark-1;
      color: $color-white;

      a {
        color: $color-white;
      }
      .icon,
      .alert_icon {
        fill: $color-white;
      }

      .alert_close {
        &:hover,
        &:focus {
          background-color: rgba($color-white, 0.05);
          .icon {
            fill: $color-white;
          }
        }
      }
    }
  }

  &_warning {
    background-color: $color-status-warning-light;
    .alert_icon {
      fill: $color-status-warning;
    }
  }

  &_success {
    background-color: $color-status-ok-light;
    .alert_icon {
      fill: $color-status-ok;
    }
  }

  &_danger {
    background-color: $color-status-critical-light;
    .alert_icon {
      fill: $color-status-critical;
    }

    &.alert_global {
      background-color: $color-status-critical;
      color: white;
      a {
        color: white;
      }
      .icon,
      .alert_icon {
        fill: white;
      }

      .alert_close {
        &:hover,
        &:focus {
          .icon {
            fill: $color-white;
          }
        }
      }
    }
  }
}

.alert {
  &_info {
    background-color: $color-status-normal-light;
    .alert_icon {
      fill: $color-status-normal;
    }

    &_dark {
      background-color: $color-dark-1;
      color: $color-white;

      a {
        color: $color-white;
      }
      .icon,
      .alert_icon {
        fill: $color-white;
      }

      .alert_close {
        &:hover,
        &:focus {
          background-color: rgba($color-white, 0.05);
          .icon {
            fill: $color-white;
          }
        }
      }
    }
  }
}

.cf-summary__content .disclaimer {
  padding: 0;
}

.cf-summary__content .h-action-card__wrapper:hover {
  box-shadow: none;
}

.page-content-wrap,
.loading-spacer {
  min-height: calc(100vh - 200px);
  // min-height: calc(100vh - 104px);

  // @media (min-width: $mq-medium) {
  //   min-height: calc(100vh - 200px);
  // }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.page-wrap--home {
  .page-content-wrap,
  .loading-spacer {
    transition: height 0s linear 1s;
    min-height: 0;

    &:after {
      content: '';
      transition: height 0s linear 1s;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      opacity: 1;
      pointer-events: none;
      z-index: 100;
      animation: fadeOut 1000ms ease 500ms;
      animation-fill-mode: forwards;
    }
  }
}
