@import './../../theme/base';

.cf-main_title {
  font-family: GT America;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 42px;

  color: $color-dark-1;

  margin: 0 0 81px;
}

.site-header .site-header__navbar.is-empty {
  overflow: visible;
}
